import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

type MultipartOptions = {
  query: string;
  variables?: { [key: string]: unknown };
  operationName: string;
  files: { [key: string]: File };
}

@Injectable({
  providedIn: 'root'
})
export class MutlipartService {
  constructor(private http: HttpClient) { }

  query<TResult>(options: MultipartOptions) {
    const formData = new FormData();
    for (const key in options.files) {
      formData.append(key, options.files[key]);
    }

    formData.append('graphql', JSON.stringify({
      query: options.query,
      variables: options.variables ?? {},
      operationName: options.operationName
    }))

    return this.http.post<{ data: TResult }>(`${environment.api.url}/graphql`, formData)
  }
}